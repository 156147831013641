import './MovingLogo.css';
import useMovingLogo from './MovingLogo.hook';

const MovingLogo = () => {
  const {position, logoDimensions} = useMovingLogo();

  return (
    <div className="app">
      <div className="moving-image" style={{ left: position.x, top: position.y }}>
        <img width={logoDimensions.width} height={logoDimensions.height} src="./kaliop-reverse.png" alt="" />
      </div>
    </div>
  );
};

export default MovingLogo;