import { useEffect, useState } from 'react';
import * as constValues from './constants';

const useMovingLogo = () => {
  const [position, setPosition] = useState({ x: 1, y: 1 });
  const [direction, setDirection] = useState({ x: 1, y: 1 });
  const [logoDimensions, setLogoDimensions] = useState({ width: constValues.LOGO_WIDTH, height: constValues.LOGO_HEIGHT });
  const [boxDimensions, setBoxDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  const changeDirection = (newDirection: string) => {
    switch(newDirection) {
      case('LEFT'):
        setDirection({ x: -1, y: direction.y });
        break;
      case('TOP'):
        setDirection({ x: direction.x, y: -1 });
        break;
      case('RIGHT'):
        setDirection({ x: 1, y: direction.y });
        break;
      case('BOTTOM'):
        setDirection({ x: direction.x, y: 1 });
        break;
    }
  }

  useEffect(() => {
    const isMobile = window.screen.width < constValues.MOBILE_SCREEN_MAX_WIDTH;
    setLogoDimensions({
      width: isMobile ? constValues.MOBILE_LOGO_WIDTH : constValues.LOGO_WIDTH,
      height: isMobile ? constValues.MOBILE_LOGO_HEIGHT : constValues.LOGO_HEIGHT,
    });

    setBoxDimensions({
      width: isMobile ? window.screen.width : window.innerWidth,
      height: isMobile ? window.screen.height : window.innerHeight,
    });

    const updatePosition = () => {
      setPosition({ x: position.x + direction.x, y: position.y + direction.y });

      if (position.x >= boxDimensions.width - logoDimensions.width - constValues.PRETTIFY_BOX_BUFFER_SIZE) {
        changeDirection('LEFT');
      } else if (position.y >= boxDimensions.height - logoDimensions.height - constValues.PRETTIFY_BOX_BUFFER_SIZE) {
        changeDirection('TOP');
      } else if (position.x <= 0) {
        changeDirection('RIGHT');
      } else if (position.y <= 0) {
        changeDirection('BOTTOM');
      }
    };

    const timer = setInterval(updatePosition, constValues.LOGO_MOVEMENT_SPEED);

    return () => clearInterval(timer);
  }, [position, direction]);

  return {
    position,
    logoDimensions,
  };
};

export default useMovingLogo;