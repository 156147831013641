import './App.css';
import MovingLogo from './MovingLogo/MovingLogo';

function App() {
  return (
    <MovingLogo />
  );
}

export default App;
